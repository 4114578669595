import React, { useState } from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import Modal, { ModalContext } from '../../../../flow-ui/flow-ui-components/src/Modal'


const PageContact = props => {
  const [open, setOpen] = useState(true)
  const handleClick = () => setOpen(!open)
  
  return(  
    <Layout {...props}>
      <Seo title='Contact' />
      <Divider />
      <Stack>
        <Modal
          isOpen={open}
          onRequestClose={handleClick}
          >
          ALOJAAA
        </Modal>
      </Stack>
    </Layout>
  )
}




export default PageContact
